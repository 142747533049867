.PartnerBanner {
    margin: 0;
    width: 100vw;
    min-height: 100px;
    padding: 0 3em;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    &:nth-of-type(1) {
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, .2);
    }

    > * {
        margin: 1em 2em;
    }

    &.no-animation {
        img {
            animation: none;
        }
    }

    &.small {
        min-height: 80px;
        
        > * {
            margin: 1em 3em;
        }

        img {
            transform: scale(.8);
        }
    }

    @media screen and (max-width: 480px) {
        > * {
            margin: 1em 3em;
        }
    }

    img {
        height:60px;
        animation: highlight 8s ease 0s infinite forwards;

        @media screen and (max-width: 480px) {
            animation: none;
            transform: scale(.8);
        }

        &.noack {
            height: 58px;
            animation-delay: 0s;
        }

        &.sportimport {
            height: 32px;
            animation-delay: 1s;
        }

        &.aerycs {
            height: 15px;
            animation-delay: 2s;
        }

        &.pdsvision {
            height: 35px;
            animation-delay: 3s;
        }

        &.webxells {
            height: 50px;
            animation-delay: 4s;
        }

        &.mucoff {
            height: 32px;
            animation-delay: 5s;
        }

        &.maxplaner {
            height: 42px;
            animation-delay: 6s;
        }

        &.h1media {
            height: 48px;
            animation-delay: 7s;
        }

        @keyframes highlight {
            0% {
                transform: scale(1) rotate(0deg);
            }
            8% {
                transform: scale(1.2) rotate(8deg);
            }
            12% {
                transform: scale(1) rotate(0deg);
            }
        }
    }
}
