.custom-slide {
    background-color: var(--color-black);
    height: 100%;
    color: var(--color-white);
    display: flex;
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 580px) {
        .image {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: .7;
        }
    }
    .image {
        flex: 1;

        .logo {
            height: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            opacity: .1;
        }
    }

    .description {
        flex: 2;
        color: var(--color-white);
        padding: 1em 2em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        p {
            opacity: .8;
            font-size: .9em;
            padding: 0 1em 0 0;

            strong {
                font-size: 1.15em;
                opacity: .95;
            }

            @media screen and (max-width: 580px) {
                opacity: .99;
                padding: 0 1em;
            }
        }

        .ctas {
            margin: 1em 0 .5em 0;
            display: flex;
            justify-content: flex-end;

            a, a:focus, a:visited {
                background-color: rgba(255, 255, 255, .075);
                border: 1px solid var(--color-pink);
                color: var(--color-pink);
                text-decoration: none;
                padding: .4em .75em;
                opacity: .7;
                transition: all .2s ease;
                font-weight: bold;

                @media screen and (max-width: 580px) {
                    border: 1px solid var(--color-white);
                    color: var(--color-white);
                }
            }

            a:hover {
                transform: scale(1.05);
            }
        }
    }
}
