.RaceDescriptionSection {
    padding: 4em 0;
    overflow: hidden;

    .main {
        display: flex;
        height: 700px;
    }
    
    .map {
        max-height: 100%;
        opacity: .3;
    }

    .keywords {
        position: relative;
        max-width: 100%;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        h2 {
            position: relative;
            font-size: 2.8em;
            line-height: 1em;
            color: var(--color-pink);
            border-bottom: none;
            display: flex;
            justify-content: center;
            align-items: center;

            &::before {
                content: '';
                position: absolute;
                width: 260%;
                padding-bottom: 260%;
                opacity: .35;
                background: radial-gradient(var(--color-pink), rgba(0, 0, 0, 0) 80%);
            }

            span {
                position: absolute;
                color: var(--color-white);
                font-size: 1.8rem;
                font-weight: 100;
                font-family: Verdana, Geneva, Tahoma, sans-serif;
                opacity: .8;
                left: 0px;
                top: 0px;
                white-space: nowrap;
                line-height: 1em;
                text-align: center;
    
                &:nth-of-type(1) {
                    top: -100px;
                    left: 60px;
                    font-weight: bolder;
                }
    
                &:nth-of-type(2) {
                    font-size: 1.1rem;
                    top: -40px;
                    left: 240px;
                }
    
                &:nth-of-type(3) {
                    font-size: 1.9rem;
                    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
                    top: 30px;
                    left: 270px;
                    opacity: .9;
                }
    
                &:nth-of-type(4) {
                    font-size: 1.1rem;
                    top: 110px;
                    left: 290px;
                    font-style: italic;
                    font-weight: bolder;
                    opacity: .7;
                }
    
                &:nth-of-type(5) {
                    font-size: 1.1rem;
                    font-family: Arial, Helvetica, sans-serif;
                    top: 200px;
                    left: 160px;
                    opacity: .6;
                }
    
                &:nth-of-type(6) {
                    font-size: 1.25rem;
                    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                    top: 195px;
                    left: -40px;
                    opacity: .6;
                }
    
                &:nth-of-type(7) {
                    font-size: 1.4rem;
                    top: 120px;
                    left: -140px;
                    opacity: .95;
                }
    
                &:nth-of-type(8) {
                    font-size: 2.2rem;
                    font-family: Arial, Helvetica, sans-serif;
                    top: 20px;
                    left: -180px;
                    font-weight: bold;
                    opacity: .95;
                }
    
                &:nth-of-type(9) {
                    font-size: 1.4rem;
                    font-family: 'Times New Roman', Times, serif;
                    top: -70px;
                    left: -100px;
                    opacity: .7;
                }
            }
        }
    }

    @media screen and (max-width: 1280px) {
        .main {
            position: relative;
        }

        .map {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
            max-height: unset;
            opacity: .1;
        }

        .keywords {
            h2 {
                &::before {
                    opacity: .4;
                }
            }
        }
    }

    @media screen and (max-width: 780px) {
        padding: 2em 0;

        .main {
            padding-top: 4em;
        }

        .keywords {
            h2 {
                font-size: 2em;
                width: 100%;
                height: 100%;

                &::before {
                    width: 540px;
                    padding-bottom: 540px;
                    opacity: .3;
                }

                span {
        
                    &:nth-of-type(1) {
                        top: 12%;
                        right: 20%;
                    }
        
                    &:nth-of-type(2) {
                        top: 19%;
                        left: 20%;
                    }
        
                    &:nth-of-type(3) {
                        top: 27%;
                        left: 43%;
                    }
        
                    &:nth-of-type(4) {
                        top: 39%;
                        right: 7%;
                        left: unset;
                    }
        
                    &:nth-of-type(5) {
                        top: 36%;
                        left: 5%;
                    }
        
                    &:nth-of-type(6) {
                        top: 65%;
                        left: 12%;
                    }
        
                    &:nth-of-type(7) {
                        top: 63%;
                        right: 18%;
                        left: unset;
                    }
        
                    &:nth-of-type(8) {
                        top: 75%;
                        left: 28%;
                    }
        
                    &:nth-of-type(9) {
                        top: 84%;
                        right: 13%;
                        left: unset;
                    }
                }
            }
        }
    }
}
