.KnownFromBanner {
    margin-bottom: .5em;
    display: flex;
    gap: 5%;
    display: none;

    h4 {
        display: none;
    }

    .logos {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        align-items: center;
        gap: 4%;

        img {
            max-height: 60px;
            width: 100%;

            &.spotify {
                max-height: 45px;
            }

            &.youtube {
                max-height: 35px;
            }

            &.tageblatt {
                max-height: 15px;
            }
        }
    }

    @media screen and (max-width: 580px) {
        flex-direction: column;
        padding: 0 1em;

        h4 {
            display: block;
            margin-bottom: .25em;
        }
        
        .logos {
            justify-content: space-between;
            img {
                height: 10vw;

                &.spotify {
                    height: 7.5vw;
                }
    
                &.youtube {
                    height: 6vw;
                }
    
                &.tageblatt {
                    height: 2.7vw;
                }
            }
        }
    }
}
