.DieZweiteLuftSlide {
    .image {
        transform: scale(1.38);
        .logo {
            background-image: url(./spotify.png);
            

            @media screen and (max-width: 1020px) {
                transform: scale(1.4);
            }

            @media screen and (max-width: 580px) {
                transform: scale(1.2);
            }
        }
    }

}
