.YoutubeSlide {
    .image {
        transform: scale(1.48);
        .logo {
            background-image: url(./youtube.png);
            opacity: .1;
            

            @media screen and (max-width: 1020px) {
                transform: scale(1.4);
            }

            @media screen and (max-width: 580px) {
                transform: scale(1.2);
            }
        }
    }
}
