.UsefulLinksSection {

    .ctas {
        display: flex;
        justify-content: center;
        gap: 1em;

        @media screen and (max-width: 480px) {
            flex-direction: column;
            a:hover {
                transform: none;
            }
        }

        a, a:focus, a:visited {
            background-color: var(--color-black);
            border: 1px solid var(--color-pink);
            color: var(--color-pink);
            text-decoration: none;
            padding: .4em .75em;
            transition: all .2s ease;
            font-weight: bold;
            font-size: 1em;
            text-align: center;

            &:hover {
                transform: scale(1.2);
            }
        }
    }

}
