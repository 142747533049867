.AthleteSection {
    .main {
        color: var(--color-white);
        display: flex;
        align-items: center;
    }

    .image {
        display: none;
        width: 300px;
        height: 500px;
        flex-shrink: 0;
        background-image: url(./jasper.png);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 2em;

        @media screen and (min-width: 1280px) {
            display: block;
        }
    }

    h2 {
        margin-top: 2em;
    }

    h3 {
        margin: 2em 0 0;
    }

    .achievements {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;

        h4 {
            margin-bottom: 0;
        }

        .rowing, .cycling {
            @media screen and (max-width: 720px){
                width: 100%;
                display: flex;
                flex-direction: column;
            }
        }
    }

    ul {
        li {
            font-size: 1em;
            p {
                margin: 0;
            }
            span {
                font-size: .8em;
                opacity: .7;
                margin-bottom: 1em;
            }
            margin-bottom: .7em;
        }
    }
}

