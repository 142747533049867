.MediaSection {
    margin-bottom: 4em;

    .main {

        .react-slideshow-container {
            .default-nav:first-of-type {
                margin-left: .5em;
                opacity: .7;
            }
            .default-nav:last-of-type {
                margin-right: .5em;
                opacity: .7;
            }
        }

        @media screen and (max-width: 580px) {
            padding: 0;

            h2 {
                margin-left: .5em;
            }
        }
    }

}
