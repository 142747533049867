.Header {
    position: relative;
    min-height: calc(100vh - 0px);
    background-image: url(./bg1.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    --margin-top: 30px;
    --margin-left: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--margin-top) var(--margin-left);

    @media screen and (max-width: 920px) {
        min-height: calc(100vh);
        --margin-top: 20px;
        --margin-left: 35px;
    }

    @media screen and (max-width: 720px) {
        --margin-top: 20px;
        --margin-left: 25px;
    }

    @media screen and (max-width: 480px) {
        --margin-top: 10px;
        --margin-left: 15px;
    }

    @media screen and (max-width: 300px) {
        --margin-top: 0px;
        --margin-left: 0px;
    }

    &:before {
      content: '';
      position: absolute;
      top: var(--margin-top);
      bottom: var(--margin-top);
      left: var(--margin-left);
      right: var(--margin-left);
      background-color: var(--color-black);
      opacity: .75;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, .4);
    }

    .JL_logo {
      position: absolute;
      height: 130px;
      width: 130px;
      background-image: url(./JL_logo.png);
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      top: calc(var(--margin-top) + 20px);
      right: calc(var(--margin-left) + 10px);

      @media screen and (max-width: 1080px) {
        height: 90px;
        width: 90px;
      }
    }

    &-wrapper {
      z-index: 1;
      color: var(--color-white);
      padding: 1em;

      h1 {
        text-align: center;
        font-size: 4em;
        margin: 0;

        span {
          font-size: .7em;
          font-weight: normal;
        }
      }

      h2 {
        text-align: right;
        border: none;
        margin: 0;
        margin-top: .25em;
        font-size: 2.25em;
        font-weight: normal;
        opacity: .8;
      }

      @media screen and (max-width: 1080px) {
        h1 {
          font-size: 3em;
        }
        h2 {
          text-align: center;
          font-size: 1.75em;
        }
      }
    }
}
