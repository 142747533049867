.TageblattSlide {
    .image {
        transform: scale(1.15);
        .logo {
            background-image: url(./tageblatt.png);
            opacity: .15;
        }
    }

}
