.EventCountdown {
    .countdown {
        display: flex;
        align-items: center;
        justify-content: center;

        .days, .hours, .minutes, .seconds {
            position: relative;
            height: 200px;
            width: 200px;
            overflow: hidden;

            @media screen and (max-width: 780px) {
                width: 80px;
                height: 80px;
                border-right: 1px solid rgba(0, 0, 0, .1);

                &.days {
                    border-left: 1px solid rgba(0, 0, 0, .1);
                }
            }

            .label {
                opacity: .4;
                font-size: 40px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                
                @media screen and (max-width: 780px) {
                    font-size: 18px;
                    opacity: .5;
                    top: 100%;
                    left: 50%;
                    transform: translate(-50%, -100%);
                }
            }

            .value {
                opacity: .4;
                font-size: 100px;
                font-weight: bold;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: var(--color-pink);
                
                @media screen and (max-width: 780px) {
                    opacity: .5;
                    font-size: 45px;
                }
            }
        }
    }
}
