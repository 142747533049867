.TeamSection {
    padding-top: 5em;

    .introduction {
        margin-bottom: 2em;
    }

    .cards {
        margin: -1em;
        display: flex;
        flex-wrap: wrap;

        .card {
            background-color: var(--color-black);
            color: var(--color-white);
            display: flex;
            flex-grow: 1;
            margin: 1em;
            width: calc((1 / 2) * 100% - 2em);
            min-width: 300px;

            .img {
                height: 170px;
                width: 170px;
                background-position: center center;
                background-size: contain;
                background-repeat: no-repeat;
                margin-right: 1.5em;
                position: relative;
                transition: transform .4s ease;

                @media screen and (max-width: 560px) {
                    height: 120px;
                    width: 120px;
                }

                &:is(.jasper .img) {
                    background-image: url(./images/Jasper.png);
                }

                &:is(.martin .img) {
                    background-image: url(./images/Martin.png);
                }

                &:is(.phillip .img) {
                    background-image: url(./images/Phillip.png);
                }

                &:is(.merle .img) {
                    background-image: url(./images/Merle.png);
                }

                &:is(.mona .img) {
                    background-image: url(./images/Mona.png);
                }

                &:is(.christian .img) {
                    background-image: url(./images/Christian.png);
                }

                &:is(.johann .img) {
                    background-image: url(./images/Johann.png);
                }

                &:is(.josephine .img) {
                    background-image: url(./images/Josephine.png);
                }
            }

            .info {
                position: relative;
                flex: 1;
                height: 100%;
                justify-content: center;
                flex-direction: column;
                padding-top: 3em;

                p {
                    font-size: 1.3em;
                    font-weight: bold;
                    margin: 0;
                    position: relative;
                    display: inline;
                    flex-grow: 0;
                    transition: font-size .2s ease;

                    &::after {
                        content: '';
                        position: absolute;
                        height: 2px;
                        width: 0;
                        background-color: var(--color-pink);
                        left: 0;
                        top: 1em;
                        transition: width .4s ease .2s;
                    }
                }
    
                span {
                    opacity: .6;
                    font-size: .85em;
                }

                .socials {
                    display: flex;
                    position: absolute;
                    bottom: 0;
        
                    a {
                        width: 16px;
                        height: 16px;
                        display: block;
                        margin-right: 1em;
                        background-position: center center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        opacity: .9;
                        color: transparent;
                        user-select: none;
        
                        &.instagram {
                            background-image: url(./logos/instagram.png);
                        }
        
                        &.mail {
                            background-image: url(./logos/email.png);
                            transform: scale(1.15);
                        }
        
                        &.strava {
                            background-image: url(./logos/strava.png);
                            opacity: .8;
                        }
                    }
                }
            }

            &:hover {
                .img {
                    transform: scale(1.2);
                }

                .info p {
                    font-size: 1.4em;

                    &::after {
                        width: 100%;
                    }
                }
            }
        }
    }
}