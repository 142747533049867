html {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;

  --color-pink: #ff3cd2;
  --color-black: #000;
  --color-white: #fff;
}

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--color-black);
  color: var(--color-white);
  min-height: 200vh;
  overflow-x: hidden;
  font-weight: lighter;
}

#root {
  width: 100%;
  overflow-x: hidden;

  & > * {
    width: 100%;
    overflow-x: hidden;
  }
}

.main {
  max-width: calc(1280px + 2em);
  margin: 0 auto;
  padding: 1em;
  font-size: 1.1em;
  line-height: 1.3em;
  overflow: hidden;
  @media screen and (max-width: 720px) {
    font-size: 1.1em;
    line-height: 1.3em;
  }
}

h2 {
  font-size: 2.2em;
  font-weight: light;
  position: relative;
  margin-bottom: .5em;
  line-height: 1.2em;

  &:not(.no-border)::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 24px;
    background-color: var(--color-pink);
    left: 0;
    bottom: 0px;
  }
  
  span {
      font-size: .7em;
      opacity: .5;
  }
}

p {
  text-align: justify;

  @media screen and (max-width: 720px) {
    text-align: left;
  }

  strong {
    background-color: var(--color-pink);
    color: var(--color-white);
    font-size: .9em;
    font-weight: normal;
    padding: .125em .3em;
    opacity: .85;

    i {
      opacity: 1;
    }
  }

  i {
      font-style: italic;
      opacity: .8;
  }
}
